@import '../../../../scss/theme-bootstrap';

.basic-noderef--padding {
  padding-top: 20px;
  padding-bottom: 20px;
  @include breakpoint($portrait-up) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
